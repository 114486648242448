$host-font-size-for-full-width: 16px;
$host-font-size-for-787: 12px;
$host-font-size-for-575: 9px;
$host-font-size-for-308: 8px;

$main-text-color: #e7ffff;
$game-name-color: #ffd234;

$wrapper-height: 40vh;

%currency-ico-code {
  text-transform: uppercase;
}

%mat-cell-rows {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

%spinner-style {
  width: 7rem;
  height: 7rem;
  margin: auto 0.5em;
}

%hide-scroll-bar {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin reels-size($size) {
  .reel {
    width: $size;
    height: $size;
  }

 .wild {
    width: $size;
  }
}
