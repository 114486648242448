/* You can add global styles to this file, and also import other style files */
@import './src/app/shared/constants/scss/variables.scss';

@font-face {
  font-family: 'Peace';
  src: url(/assets/fonts/Peace.ttf) format('woff');
}

body {
  margin: 0;
  font-family: Peace, "Helvetica Neue", sans-serif;
  font-size: $host-font-size-for-full-width;
  background-color: transparent;
  color: $main-text-color;
}

.mat-dialog-container {
  padding: 0 !important;
  background-color: black;
}

.reward-details-txt {
  font-family: Peace, "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.wrapper-container {
  padding: 20px;
  border-radius: 6px;
  box-shadow: inset 0 5px 0 #333350, inset 0px -4px 0px #161629;
  background-color: #1f2034;
  border: 3px solid #000000;
}

.close-dialog {
  color: white;
  position: absolute;
  right: 13px;
  top: 10px;
  background: transparent;
  border: none;
  outline: none;
}

.wrapper-details {
  height: 100%;
  position: relative;
  padding: 20px;
  border-radius: 6px;
  background-color: rgba(27,27,42,0.74);
  border: 3px solid #000000;
}

hr {
  background: #fff;
  color: $main-text-color;
  padding: 1px 0;
}

h2, h3 {
  font-size: 2em;
  color: #e7ffff;
  text-transform: uppercase;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

p {
  color: $main-text-color;
}

.mat-table {
  border-radius: 6px;
  background-color: rgba(27, 27, 42, 0.7);
  border: 3px solid #000000;
  padding-bottom: 20px;

  .mat-header-cell {
    color: $main-text-color;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-family: Peace, "Helvetica Neue", sans-serif;
    text-transform: uppercase;
    font-size: 18px;
  }

  .mat-cell {
    color: $main-text-color;
    text-transform: uppercase;
    font-family: Peace, "Helvetica Neue", sans-serif;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    padding-top: 5px;
    margin-bottom: 5px;
  }

  .mat-row {
    margin: 0 auto;
    width: 96%;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: inset 0 5px 0 #333350, inset 0px -4px 0px #161629;
    background-color: #1f2034;
    border: 3px solid #000000;
    &.active {
      box-shadow: inset 0 5px 0 #494975, inset 0px -4px 0px #161629;
      background-color: #373856;
    }
  }

  .mat-header-row {
    width: 101.2%;
    margin-left: -8px;
    margin-top: -3px;
    background: url("/assets/images/bgTableHeader.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    border-bottom-color: transparent;
    min-height: 35px;
    //width: 1568px;
    height: 75px;
    padding-bottom: 13px;
  }

  .active {
    background-color: #9c9c9c;
  }
}

.wrapper {
  margin-top: 20px;
}

@media screen and (max-width: 787px) {
  body {
    font-size: $host-font-size-for-787;
  }

  .mat-table {

    .mat-cell,
    .mat-header-cell {
      font-size: 9px;
      padding-left: 5px;
    }
  }
}
